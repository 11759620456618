import React, { useState } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { useCoachingQuery } from '../hooks/coaching'

import { Card } from './Card'

const Button = loadable(() => import('./Button'))

const PageBuilderCoaching = ({ block }) => {
  const language = process.env.GATSBY_SITE_LANGUAGE
  const data = useCoachingQuery()
  const [showMore, setShowMore] = useState(false)

  const courses =
    (block.specific?.length > 0 && block.specific) ||
    data.allSanityCoaching.edges.map(({ node }) => node)
  
  const courseList = Array.from(courses)

  if (!block.showAll && !showMore) courseList.splice(2)

  const cardClasses = 'col-span-8 md:col-span-4'

  return (
    <section className="container grid grid-cols-12 my-16">
      {block.title && (
        <div className="col-span-12 xl:col-span-10 xl:col-start-2">
          <h2 className="mb-8 text-lg font-bold text-gray-800 lg:mb-12">
            {block.title}
          </h2>
        </div>
      )}
      {courseList && (
        <div className="grid grid-cols-8 col-span-12 gap-8 xl:col-span-10 xl:col-start-2 xl:gap-12">
          {courseList.map(course => {
            const featureImage =
              (course.bgImg?.asset && course.bgImg) ||
              course.gallery.images.find(image => image.featured)

            const content = {
              id: course._id,
              title: course.title,
              slug: `/online-coaching/${course.slug.current}/`,
              features: course.detailsCard,
              featuredImage: featureImage,
              description: course.description || course._rawDescription,
            }

            return (
              <Card
                key={course._id}
                content={content}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                cardClasses={cardClasses}
                hosts={course.coach}
                popular={course.popular}
              />
            )
          })}
        </div>
      )}

      {!block.showAll && courses.length > 2 && (
        <div
          className={`col-span-12 xl:col-span-10 xl:col-start-2 pt-12 ${
            showMore ? 'hidden' : ''
          }`}
        >
          <Button
            colour="ghostBlueThick"
            showArrowIcon
            setShowMore={setShowMore}
          >
            {language == 'en' ? `Explore all online coaching options` : `Esplora tutte le opzioni di coaching online`}
          </Button>
        </div>
      )}
    </section>
  )
}

PageBuilderCoaching.propTypes = {
  block: PropTypes.object,
  // raw: PropTypes.object,
  showAll: PropTypes.bool,
}

PageBuilderCoaching.defaultProps = {
  block: {},
  showAll: false,
}

export default PageBuilderCoaching
