import { graphql, useStaticQuery } from 'gatsby'

export const useCoachingQuery = () =>
  useStaticQuery(graphql`
    query CoachingLinks {
      allSanityCoaching {
        edges {
          node {
            _id
            title
            slug {
              current
            }
            detailsCard
            _rawDescription(resolveReferences: { maxDepth: 10 })
            popular
            coach {
              image {
                asset {
                  _id
                }
                crop {
                  _key
                  _type
                  top
                  bottom
                  left
                  right
                }
                hotspot {
                  _key
                  _type
                  x
                  y
                  height
                  width
                }
                alt
              }
            }
            bgImg {
              asset {
                _id
              }
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
              alt
            }
            gallery {
              images {
                featured
                asset {
                  _id
                }
                crop {
                  _key
                  _type
                  top
                  bottom
                  left
                  right
                }
                hotspot {
                  _key
                  _type
                  x
                  y
                  height
                  width
                }
                alt
              }
            }
          }
        }
      }
    }
  `)
